import React from "react";
import { useNavigate } from "react-router-dom";
import * as links from "constants/url";

import styles from "./Header.module.scss";
import location from "assets/img/location.png";
import seller from "assets/img/seller.png";
import notification from "assets/img/notification.svg";
import { Link, Logo, Button, ModalWindow, ModalCity, Select } from "components";

import { CHANGE_IS_OPEN_CITY_MODAL } from "constants/actionType";
import store from "../../redux/stores";
import { useSelector } from "react-redux";

const SellerHeader = () => {
  const navigate = useNavigate();
  const sellerNotificationsCount = useSelector(
    (state) => state.sellerNotificationsCount
  );
  const isOpenCity = useSelector((state) => state.isOpenCityModal);

  const items = [
    {
      title: "Мой магазин",
      items: [
        { title: "Товары", path: links.SHOP_PRODUCTS.url },
        { title: "Отзывы и вопросы", path: links.SHOP_REVIEWS.url },
        { title: "Чат с покупателями", path: links.CHAT_WITH_BUYERS.url },
      ],
    },
    {
      title: "Поставки",
      items: [
        { title: "Управление заказами", path: links.ORDERS_HANDLER.url },
        { title: "Склады", path: links.WAREHOUSE.url },
        { title: "Управление доставкой", path: links.TRACKING_ORDERS.url },
        { title: "Возвраты", path: links.CANCELED_ORDERS.url },
      ],
    },
    {
      title: "Акции и скидки",
      path: "/seller/deliveries/promotions_discount/all",
      urls: [
        { path: "/seller/deliveries/promotions_discount/all" },
        { path: "/seller/deliveries/promotions_discount/my" },
      ],
    },
    {
      title: "Аналитика",
      items: [
        { title: "Трендовая аналитика", path: links.TREND_ANALYTICS.url },
        { title: "Аналитика по услугам", path: links.SERVICES_ANALYTICS.url },
        {
          title: "Воронка продаж",
          path: links.SALES_FUNNEL.url + "?tab=overall_data",
        },
        {
          title: "Финансовая отчетность",
          path: links.FINANCIAL_STATEMENTS.url,
        },
        // {
        //   title: "Маркетинговая аналитика",
        //   path: links.MARKETING_ANALYTICS.url,
        // },
        { title: "Клиенты", path: links.CLIENTS.url },
      ],
      urls: [{ path: links.SALES_FUNNEL.url }],
    },
  ];

  return (
    <header className={styles.header}>
      <div
        className={styles.first_header}
        style={{ backgroundColor: "#5F6B69", width: "100%" }}
      >
        <div
          className={styles.first_header}
          style={{ backgroundColor: "#5F6B69", width: "100%" }}
        >
          {/* <Button
            text={
              <>
                <img
                  src={location}
                  alt="Местоположение"
                  style={{ height: "1.5vh", marginRight: "0.2vw" }}
                />
                {localStorage.getItem("city") || "Выберите город"}
              </>
            }
            marginRight="4vw"
            bgColor="unset"
            fontWeight="400"
            click={() =>
              store.dispatch({
                type: CHANGE_IS_OPEN_CITY_MODAL,
                isOpenCityModal: true,
              })
            }
          /> */}
          <Link
            path={process.env.REACT_APP_USER_URL + links.ABOUT_US.url}
            className={styles.link}
            color="#fff"
          >
            {links.ABOUT_US.title}
          </Link>
          <Link
            path={
              process.env.REACT_APP_USER_URL +
              links.SHALFEY.url +
              links.CONTACTS.url
            }
            className={styles.link}
            color="#fff"
          >
            {links.CONTACTS.title}
          </Link>
          <Link
            path={
              process.env.REACT_APP_USER_URL +
              links.BUYERS.url +
              links.BUYERS.url
            }
            className={styles.link}
            color="#fff"
          >
            {links.BUYERS.title}
          </Link>
          <Link
            path={links.INSTRACTION.url}
            className={styles.link}
            color="#fff"
          >
            <img
              src={seller}
              alt={links.INSTRACTION.title}
              style={{ marginRight: "0.5vw", height: "1.4vh" }}
            />
            {links.INSTRACTION.title}
          </Link>
        </div>
      </div>
      <div className={styles.second_header}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link path="/seller">
            <Logo isSeller marginLeft="2vw" />
          </Link>
          <div style={{ display: "flex", marginLeft: "6vw" }}>
            {items.map((item, index) => (
              <Select
                key={item.title}
                value={item.title}
                options={item.items?.map((el) => {
                  return {
                    text: el.title,
                    value: el.path,
                  };
                })}
                path={item.path}
                marginRight="3vw"
                backgroundColor={
                  item.items?.find(
                    (el) => el.path === window.location.pathname
                  ) ||
                  item.urls?.find((el) => el.path === window.location.pathname)
                    ? "#EAF8E7"
                    : "#fff"
                }
                onChange={(el) => navigate(el)}
              />
            ))}
          </div>
        </div>
        <div
          style={{
            height: "7.1vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Link
            path={links.SELLER_NOTIFICATIONS.url}
            className={styles.notification}
          >
            <img src={notification} alt="Уведомления" />
            {!!sellerNotificationsCount && (
              <span className={styles.notification_count}>
                {sellerNotificationsCount <= 99
                  ? sellerNotificationsCount
                  : "99+"}
              </span>
            )}
          </Link>
          <Button
            text={"Профиль покупателя"}
            bgColor="#F2F7F4"
            color="#5F6B69"
            padding="1.5vh 1vw"
            marginRight="4vw"
            click={() => {
              window.location.href =
                process.env.REACT_APP_USER_URL +
                links.PROFILE.url +
                "?tab=trackOrder";
            }}
          />
        </div>
      </div>
      <ModalWindow
        isOpen={isOpenCity}
        onClose={() =>
          store.dispatch({
            type: CHANGE_IS_OPEN_CITY_MODAL,
            isOpenCityModal: false,
          })
        }
      >
        <ModalCity
          onClose={() =>
            store.dispatch({
              type: CHANGE_IS_OPEN_CITY_MODAL,
              isOpenCityModal: false,
            })
          }
        />
      </ModalWindow>
    </header>
  );
};

export { SellerHeader };
