import React, { useState, useEffect } from "react";
import styles from "./Warehouse.module.scss";
import { Button, Input, Tabs, ModalWindow } from "components";
import EmptyProduct from "assets/img/empty_product.png";
import add_photo from "assets/img/add_photo.png";
import search_icon from "assets/img/search.svg";
import cancel from "assets/img/cancel.svg";
import details from "assets/img/details.svg";
import delete_image from "assets/img/delete_image.svg";
import { PROFILE } from "constants/url";
import { useNavigate } from "react-router-dom";
import { ERR_NETWORK } from "constants/status";
import { Helmet } from "react-helmet";
import { axiosDeliveries } from "plugins/axios";
import { axiosSeller } from "plugins/axios";

const Warehouse = () => {
  const navigate = useNavigate();
  const [warehouses, setWarehouses] = useState([]);
  const [sorting, setSorting] = useState("address");
  const [searchText, setSearchText] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModal, setIsCreateModal] = useState(true);
  const [warehouse, setWarehouse] = useState({
    address: "",
    working_hours: "",
    shipping_hours: "",
  });
  const [error, setError] = useState({
    address: "",
    working_hours: "",
    shipping_hours: "",
    errorMessage: "",
  });

  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);

  const getWarehouses = async () => {
    await axiosSeller.getSellerNotificationsCount();
    let result = await axiosDeliveries.getWarehouses(
      "type=" + sorting + "&page=" + currentPage + "&query=" + searchText
    );
    if (result?.status === 403) {
      navigate(PROFILE.url);
    }
    if (!result?.results?.length || result?.message) {
      setWasLastList(true);
      return;
    }
    setPreviousPage(currentPage);
    setWarehouses([...warehouses, ...result.results]);
  };

  const exportToExel = async () => {
    let res = await axiosDeliveries.getExelWarehouses();

    const blob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.speadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Склады.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const submitData = async () => {
    setError({
      address: "",
      working_hours: "",
      shipping_hours: "",
      errorMessage: "",
    });
    let valid = true;
    if (warehouse.address === "") {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          address: "Введите адрес",
        };
      });
    }
    if (valid) {
      let response = null;
      if (isCreateModal) {
        response = await axiosDeliveries.addWarehouse(warehouse);
      } else {
        response = await axiosDeliveries.updateWarehouse(warehouse);
      }
      if (response.status === 400) {
        if (
          response.data.Error ===
          "Incorrect hours formats. Must be [0-2].[0-59]-[0-2].[0-59]"
        ) {
          setError((prev) => {
            return {
              ...prev,
              working_hours: "Введите данные в формате 0.00-23.59",
              shipping_hours: "Введите данные в формате 0.00-23.59",
            };
          });
        } else if (response.data.Error === "Incorrect address") {
          setError((prev) => {
            return {
              ...prev,
              address: "Заполните поле адреса склада правильно",
            };
          });
        } else if (
          response.data.Error === "Such warehouse already exists" ||
          response.data.Error === "Such warehouse already created"
        ) {
          setError((prev) => {
            return {
              ...prev,
              address: "Такой склад уже существует",
            };
          });
        } else {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: response.data.Error,
            };
          });
        }
      } else {
        if (response.status === 201 || response.status === 204) {
          setWarehouses([]);
          setWasLastList(false);
          setCurrentPage(1);
          setPreviousPage(0);
          setIsModalOpen(false);
        } else {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: ERR_NETWORK.message,
            };
          });
        }
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Проверьте входные данные",
        };
      });
    }
  };

  const deleteWareHouse = async (id) => {
    await axiosDeliveries.deleteWarehouse(id);
    setWarehouses([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getWarehouses();
    }
  }, [currentPage, previousPage, wasLastList]);

  useEffect(() => {
    setWarehouses([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  }, [sorting]);

  return (
    <div className={styles.content} onScroll={onScroll}>
      <Helmet title="Склады">
        <meta name="robots" content="noindex" />
        <meta name="description" content="Shalfey : Склады" />
        <link rel="canonical" href="/seller/deliveries/warehouse" />
      </Helmet>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <h1 className={styles.heading_title}>Склады</h1>
        <Button
          text="Скачать информацию о складах в Excel"
          padding="1.5vh 1vw"
          bgColor="#EAF8E7"
          color="#5F6B69"
          prepend={
            <img
              src={details}
              alt="Скачать информацию о складах в Excel"
              style={{ aspectRatio: 1, height: "2vh", margin: "0" }}
            />
          }
          click={exportToExel}
        />
      </div>
      <Tabs
        tabs={[
          { name: "address", title: "По адресу" },
          { name: "time", title: "Время работы" },
        ]}
        currentTab={sorting}
        margin="4vh 0"
        changeTab={setSorting}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Input
          light
          placeholder="Введите адрес склада"
          value={searchText}
          fontColor="#5F6B69"
          width="18vw"
          margin="0 0 4vh"
          prepend={
            <img
              src={search_icon}
              style={{ height: "2.5vh" }}
              alt="search"
              onClick={() => {
                setWarehouses([]);
                setWasLastList(false);
                setCurrentPage(1);
                setPreviousPage(0);
              }}
            />
          }
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setWarehouses([]);
              setWasLastList(false);
              setCurrentPage(1);
              setPreviousPage(0);
            }
          }}
        />
        {/* <Button
          text="Добавить склад"
          padding="1.5vh 1vw"
          light
          prepend={
            <img
              src={plus}
              alt="Добавить склад"
              style={{ aspectRatio: 1, height: "2vh", margin: "0" }}
            />
          }
          click={() => {
            setIsModalOpen(true);
            setIsCreateModal(true);
          }}
        /> */}
      </div>

      <table>
        <thead>
          <tr>
            <td>Номер склада</td>
            <td>Адрес</td>
            <td>Время работы</td>
            <td>Время отгрузки</td>
            <td style={{ paddingLeft: "2vw", paddingRight: "10vw" }}>Фото</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {warehouses.map((warehouse) => (
            <tr key={warehouse.id}>
              <td style={{ paddingLeft: "2vw" }}>{warehouse.id}</td>
              <td>{warehouse.address}</td>
              <td>{warehouse.working_hours}</td>
              <td>{warehouse.shipping_hours}</td>
              <td>
                <img
                  src={warehouse.pic_url || EmptyProduct}
                  alt={warehouse.address}
                />
              </td>
              <td>
                <div style={{ flexDirection: "column" }}>
                  <Button
                    text="Редактировать"
                    padding="1vh 0.9vw"
                    marginBottom="1vh"
                    click={() => {
                      setWarehouse(warehouse);
                      setIsCreateModal(false);
                      setIsModalOpen(true);
                    }}
                  />
                  <Button
                    text="Удалить"
                    padding="1vh 0.9vw"
                    bgColor="transparent"
                    color="#DC7413"
                    prepend={
                      <img
                        src={cancel}
                        alt="Удалить"
                        style={{ width: "1vw", height: "1.3vh", margin: "0" }}
                      />
                    }
                    click={() => deleteWareHouse(warehouse.id)}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalWindow
        isOpen={isModalOpen}
        width="32vw"
        onClose={() => {
          setIsModalOpen(false);
          setError({
            address: "",
            working_hours: "",
            shipping_hours: "",
            errorMessage: "",
          });
          setWarehouse({
            address: "",
            working_hours: "",
            shipping_hours: "",
            photo: "",
            pic_url: "",
          });
        }}
      >
        <div className={styles.form}>
          <div>
            <p>
              {isCreateModal
                ? "Добавление склада"
                : "Редактирование информации"}
            </p>
            <div>
              <p>
                - порядок указания элементов адреса следует общепринятому
                принципу: индекс &rarr; город &rarr; улица &rarr; дом &rarr;
                расширение дома;
              </p>
              <p>
                - наименование города и дома можно указывать без аббревиатур и
                полных типов элемента адреса (город и дом), остальные элементы
                адреса должны хранить полные наименования типов элемента (улица,
                проспект, корпус, квартира и т.д.) или же их официальные
                аббревиатуры (ул, пр-кт, кв и т.д.);
              </p>
              <p>- допускается указание аббревиатур с точкой и без;</p>
              <p>
                - элементы адреса должны быть отделены друг от друга запятыми.
              </p>
            </div>
            <Input
              green
              placeholder="Адрес"
              value={warehouse.address}
              fontColor="#5F6B69"
              margin="0 0 4vh"
              errorMessage={error.address}
              onChange={(e) =>
                setWarehouse({ ...warehouse, address: e.target.value })
              }
            />
            <Input
              green
              placeholder="Время работы"
              value={warehouse.working_hours}
              fontColor="#5F6B69"
              margin="0 0 4vh"
              errorMessage={error.working_hours}
              onChange={(e) =>
                setWarehouse({ ...warehouse, working_hours: e.target.value })
              }
            />
            <Input
              green
              backgroundColor="#F8FBF9"
              placeholder="Время отгрузки"
              value={warehouse.shipping_hours}
              fontColor="#5F6B69"
              margin="0 0 4vh"
              errorMessage={error.shipping_hours}
              onChange={(e) =>
                setWarehouse({ ...warehouse, shipping_hours: e.target.value })
              }
            />
            <div className={styles.title}>
              {isCreateModal ? "Добавить фотографию" : "Фотография"}
            </div>
            <div className={styles.upload_img}>
              {warehouse.photo ? (
                <div>
                  <img
                    className={styles.delete}
                    src={delete_image}
                    alt="Удалить картинку"
                    onClick={() =>
                      setWarehouse({
                        ...warehouse,
                        photo: "",
                      })
                    }
                  />
                  <img
                    src={URL.createObjectURL(warehouse.photo)}
                    alt="Картинка"
                  />
                </div>
              ) : warehouse.pic_url ? (
                <div>
                  <img
                    className={styles.delete}
                    src={delete_image}
                    alt="Удалить картинку"
                    onClick={() =>
                      setWarehouse({
                        ...warehouse,
                        pic_url: "",
                      })
                    }
                  />
                  <img src={warehouse.pic_url} alt="Картинка" />
                </div>
              ) : (
                <label className={styles.upload_img}>
                  <img
                    src={add_photo}
                    className={styles.add_photo}
                    alt="Картинка"
                  />
                  <input
                    type="file"
                    name="Фото"
                    onChange={(event) =>
                      setWarehouse({
                        ...warehouse,
                        photo: event.target.files[0],
                      })
                    }
                  />
                </label>
              )}
            </div>
          </div>
          <div>
            {!!error.errorMessage && (
              <div className={styles.error}>{error.errorMessage}</div>
            )}
            <Button
              text={isCreateModal ? "Добавить" : "Редактировать"}
              padding="2vh 0"
              click={submitData}
            />
          </div>
        </div>
      </ModalWindow>
    </div>
  );
};

export { Warehouse };
