import React, { useState, useEffect } from "react";
import styles from "./TrackingOrders.module.scss";
import { Button, Input, Checkbox, ModalWindow, Link } from "components";
import filter from "assets/img/filter.svg";
import search_icon from "assets/img/search.svg";
import { Helmet } from "react-helmet";
import { axiosDeliveries } from "plugins/axios";
import { useOutsideClick } from "hooks";
import { vh, vw } from "plugins/convertPXtoVHVW";
import { PROFILE } from "constants/url";
import { useNavigate } from "react-router-dom";

const TrackingOrders = () => {
  const navigate = useNavigate();
  const [trackingOrders, setTrackingOrders] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState("");
  const [typesOfMedicine, setTypesOfMedicine] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [medicine, setMedicine] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openMedicineSelect, setOpenMedicineSelect] = useState(false);
  const [openCategoriesSelect, setOpenCategoriesSelect] = useState(false);
  const [openDateSelect, setOpenDateSelect] = useState(false);

  const refCategoriesSelect = useOutsideClick(() =>
    setOpenCategoriesSelect(false)
  );
  const refMedicineSelect = useOutsideClick(() => setOpenMedicineSelect(false));
  const refDateSelect = useOutsideClick(() => setOpenDateSelect(false));

  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);

  const getTrackingOrders = async () => {
    let result = await axiosDeliveries.getTrackingOrders(
      {
        query: searchText,
        categories,
        types_of_medicine: medicine,
        date,
      },
      currentPage
    );
    if (result?.status === 403) {
      navigate(PROFILE.url);
    }
    if (!result?.results?.length || result?.message) {
      setWasLastList(true);
      return;
    }
    setPreviousPage(currentPage);
    setTrackingOrders([...trackingOrders, ...result.results]);
  };

  const getFilterParams = async () => {
    let resp = await axiosDeliveries.getFilterParams();
    setTypesOfMedicine(resp.types_of_medicine || []);
    setCategoriesData(resp.categories || []);
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getTrackingOrders();
    }
  }, [currentPage, previousPage, wasLastList]);

  useEffect(() => {
    getFilterParams();
  }, []);

  return (
    <div className={styles.content} onScroll={onScroll}>
      <Helmet title="Отслеживание статуса заказа">
        <meta name="robots" content="noindex" />
        <meta
          name="description"
          content="Shalfey : Отслеживание статуса заказа"
        />
        <link rel="canonical" href="/seller/deliveries/tracking_orders" />
      </Helmet>
      <h1 className={styles.heading}>Отслеживание статуса заказа</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "4vh",
        }}
      >
        <Input
          light
          placeholder="Введите артикул или название товара"
          value={searchText}
          fontColor="#5F6B69"
          width="18vw"
          prepend={
            <img
              src={search_icon}
              style={{ height: "2.5vh" }}
              alt="search"
              onClick={() => {
                setTrackingOrders([]);
                setWasLastList(false);
                setCurrentPage(1);
                setPreviousPage(0);
              }}
            />
          }
          onChange={(ev) => setSearchText(ev.target.value)}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              setTrackingOrders([]);
              setWasLastList(false);
              setCurrentPage(1);
              setPreviousPage(0);
            }
          }}
        />
        <div
          id="image"
          className={styles.navImage}
          onClick={() => setIsModalOpen(true)}
        >
          <img src={filter} alt="menu" />
          Фильтр
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <td>Номер заказа</td>
            <td>Компания доставки</td>
            <td>Cостав заказа</td>
            <td>Трек-номер</td>
            <td>Статус заказа</td>
          </tr>
        </thead>
        <tbody>
          {trackingOrders.map((trackingOrder) => (
            <tr key={trackingOrder.order_number}>
              <td>{trackingOrder.order_number}</td>
              <td>
                {trackingOrder.delivery_company === "PT"
                  ? "Почта России"
                  : trackingOrder.delivery_company}
              </td>
              <td>
                {trackingOrder.content?.map((item, index) => (
                  <div key={index} className={styles.product}>
                    <p>
                      {index + 1}. Арт. {item.product.vendor_code}
                    </p>
                    <p className={styles.one_line}>{item.product.name}</p>
                    <div className={styles.price}>
                      <p>{item.count} шт.</p>
                      <p>{item.price} р.</p>
                    </div>
                  </div>
                ))}
              </td>
              <td>
                <div className={styles.product}>
                  <p>{trackingOrder["track-number"].number}</p>
                  <Link
                    path={trackingOrder["track-number"].url}
                    color="#55C83D"
                    marginTop="1vh"
                    textDecoration="underline"
                    target="_blank"
                  >
                    Ссылка для отслеживания
                  </Link>
                </div>
              </td>
              <td>{trackingOrder.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ModalWindow
        isOpen={isModalOpen}
        width="32vw"
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <div className={styles.form}>
          <p>Фильтры</p>
          <div ref={refCategoriesSelect} className={styles.custom_select}>
            <button
              className={openCategoriesSelect ? styles.open : null}
              onClick={() => {
                if (categoriesData.length)
                  setOpenCategoriesSelect(!openCategoriesSelect);
              }}
            >
              <span>Категория</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vh(12)}
                height={vh(13)}
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                  stroke="#5F6B69"
                  strokeWidth="0.61828"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <ul className={openCategoriesSelect ? styles.open : null}>
              <div>
                {categoriesData.map((symptom) => (
                  <li
                    key={symptom.id}
                    className={symptom.isSelected ? styles.select : null}
                  >
                    <span>
                      <label>
                        <Checkbox
                          isSelected={categories.includes(symptom.id)}
                          width={vh(16)}
                          height={vh(16)}
                          marginRight={vw(10)}
                          styleBack={{ borderRadius: vh(3) }}
                          change={() =>
                            setCategories(
                              categories.includes(symptom.id)
                                ? categories.filter(
                                    (newValue) => newValue !== symptom.id
                                  )
                                : [...categories, symptom.id]
                            )
                          }
                        />
                        {symptom.name}
                      </label>
                    </span>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <div ref={refMedicineSelect} className={styles.custom_select}>
            <button
              className={openMedicineSelect ? styles.open : null}
              onClick={() => {
                if (typesOfMedicine.length)
                  setOpenMedicineSelect(!openMedicineSelect);
              }}
            >
              <span>Вид медицины</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vh(12)}
                height={vh(13)}
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                  stroke="#5F6B69"
                  strokeWidth="0.61828"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <ul className={openMedicineSelect ? styles.open : null}>
              <div>
                {typesOfMedicine.map((symptom) => (
                  <li
                    key={symptom.id}
                    className={symptom.isSelected ? styles.select : null}
                  >
                    <span>
                      <label>
                        <Checkbox
                          isSelected={medicine.includes(symptom.id)}
                          width={vh(16)}
                          height={vh(16)}
                          marginRight={vw(10)}
                          styleBack={{ borderRadius: vh(3) }}
                          change={() =>
                            setMedicine(
                              medicine.includes(symptom.id)
                                ? medicine.filter(
                                    (newValue) => newValue !== symptom.id
                                  )
                                : [...medicine, symptom.id]
                            )
                          }
                        />
                        {symptom.name}
                      </label>
                    </span>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <div ref={refDateSelect} className={styles.custom_select}>
            <button
              className={openDateSelect ? styles.open : null}
              onClick={() => setOpenDateSelect(!openDateSelect)}
            >
              <span>По дате</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={vh(12)}
                height={vh(13)}
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                  stroke="#5F6B69"
                  strokeWidth="0.61828"
                  strokeLinecap="round"
                />
              </svg>
            </button>
            <ul className={`${openDateSelect ? styles.open : null}`}>
              <div>
                {[
                  { value: "new", name: "Сначала новые" },
                  { value: "old", name: "Сначала старые" },
                ].map((category) => (
                  <li
                    key={category.value}
                    onClick={() => setDate(category.value)}
                  >
                    <span
                      className={date === category.value ? styles.select : null}
                    >
                      {category.name}
                    </span>
                  </li>
                ))}
              </div>
            </ul>
          </div>
          <Button
            text="Готово"
            padding="1.6vh 2.5vh"
            margin="0 0 3vh"
            click={() => {
              setTrackingOrders([]);
              setWasLastList(false);
              setCurrentPage(1);
              setPreviousPage(0);
            }}
          />
          <Button
            text="Очистить"
            bgColor="transparent"
            color="#9CA5A3"
            padding="1.6vh 2.5vh"
            click={() => {
              setMedicine([]);
              setCategories([]);
              setDate("");
            }}
          />
        </div>
      </ModalWindow>
    </div>
  );
};

export { TrackingOrders };
