import step_1 from "assets/img/step_1.png";
import step_2 from "assets/img/step_2.png";
import step_3 from "assets/img/step_3.png";
import step_1_2 from "assets/img/step_1_2.png";
import step_2_2 from "assets/img/step_2_2.png";
import step_3_2 from "assets/img/step_3_2.png";
import vector_25 from "assets/img/vector_25.svg";
import arrow_2 from "assets/img/arrow_2.svg";
import arrow_green from "assets/img/arrow_green.svg";
import birdie from "assets/img/birdie.svg";
import main_photo from "assets/img/main_photo.svg";
import small_photo from "assets/img/small_photo.svg";
import delete_image from "assets/img/delete_image.svg";

import styles from "./CreateProduct.module.scss";
import React, { useEffect, useState } from "react";
import { ProductInfo, Checkbox } from "components";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { axiosSeller } from "plugins/axios";

const pics = [
  {
    id: 1,
    pic: main_photo,
    nameProductFields: "pic_1",
  },
  {
    id: 2,
    pic: small_photo,
    nameProductFields: "pic_2",
  },
  {
    id: 3,
    pic: small_photo,
    nameProductFields: "pic_3",
  },
  {
    id: 4,
    pic: small_photo,
    nameProductFields: "pic_4",
  },
  {
    id: 5,
    pic: small_photo,
    nameProductFields: "pic_5",
  },
  {
    id: 6,
    pic: small_photo,
    nameProductFields: "pic_6",
  },
  {
    id: 7,
    pic: small_photo,
    nameProductFields: "pic_7",
  },
  {
    id: 8,
    pic: small_photo,
    nameProductFields: "pic_8",
  },
  {
    id: 9,
    pic: small_photo,
    nameProductFields: "pic_9",
  },
];

const CreateProduct = () => {
  const navigate = useNavigate();
  const [medicine, setMedicine] = useState([]);
  const [symptom_groups, setSymptom_groups] = useState([]);
  const [indications, setIndications] = useState([]);
  const [medicine_forms, setMedicine_forms] = useState([]);
  const [year_classification, setYear_classification] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openCategory, setOpenCategory] = useState([]);
  const [emptyFields, setEmptyFields] = useState([]);

  const [tab, setTab] = useState(1);

  const [openSelect, setOpenSelect] = useState({
    openMed: false,
    openSymptomGroups: false,
    openYearClassification: false,
    openIllnesses: false,
    openCat: false,
    openMedicineForms: false,
  });

  const [productFields, setProductFields] = useState({
    name: "",
    brand: "",
    type_of_medicine: null,
    type_of_medicine_names: [],
    composition: "",
    contradictions: "",
    storage_conditions: "",
    medicine_format: [],
    medicine_format_names: [],
    manufacturer_country: "",
    indications: [],
    indications_names: [],
    categories: [],
    categories_names: [],
    symptoms: [],
    symptoms_names: [],
    year: [],
    year_names: [],
    length: "",
    width: "",
    height: "",
    weight: "",
    count: "",
    vat: "",
    vendor_code: "",
    barcode: "",
    price: "",
    description: "",
    dosage: "",
    application_methods: "",
    delivery_type: "CR",
  });
  const [images, setImages] = useState([]);

  const getMedicineCreateProduct = async () => {
    await axiosSeller.getSellerNotificationsCount();
    let result = await axiosSeller.getMedicineCreateProduct();

    setMedicine(result.types_of_medicine || []);
    setSymptom_groups(result.symptom_groups || []);
    setIndications(result.indications || []);
    setMedicine_forms(result.medicine_forms || []);
    setYear_classification(result.year_classification || []);
  };

  const checkProperties = (obj) => {
    let fields = [];
    for (let key in obj) {
      if (
        (obj[key] === null || obj[key] == "" || obj[key] == []) &&
        key !== "categories" &&
        key !== "categories_names"
      )
        fields.push(key);
    }
    if (fields.length > 0 || pics[0].picProductFields == "") return fields;
    else {
      setErrorMessage("");
      return false;
    }
  };

  const showFields = (obj) => {
    setEmptyFields(obj);
    setErrorMessage("Заполните все поля. ");
  };

  const createProduct = async () => {
    setErrorMessage("");

    let result = await axiosSeller.createProduct(productFields, images);
    if (result.status === 201) {
      navigate("/seller/shop/products");
    }
    if (result.status === 413) {
      setErrorMessage(
        "Большой размер картинок. Добавьте картинки меньше, пожалуйста"
      );
    } else if (result.status === 400) {
      if (
        result.data.Error === "Such product already exists" ||
        result.data.Error === "Product with such vendor code already exists"
      ) {
        setErrorMessage(
          "Такой товар уже существует.(У каждого продукта должно быть уникальное название.)"
        );
      }
      if (result.data.Error === "Necessary fields are not given") {
        setErrorMessage("Заполните все поля.");
      }
      if (
        result.data.Error ===
        "You must provide warehouse data and DPD data before creating a product"
      ) {
        setErrorMessage(
          "Вы должны предоставить данные о складе и DPD прежде чем создавать товар."
        );
      }
    }
  };
  const openCategories = async (id, name) => {
    let result = await axiosSeller.getCategoriesCreateProduct(id);
    setCategories(result.categories || []);
    setProductFields({
      ...productFields,
      type_of_medicine: id,
      type_of_medicine_names: name,
      categories: [],
    });
  };

  const deletePicture = async (image) => {
    setImages(images.filter((item) => item !== image));
  };

  const addOrRemoveProductFields = (item, field, field_name) => {
    if (productFields[field].find((element) => element === item.id)) {
      if (field === "medicine_format") {
        setProductFields({ ...productFields, [field]: [], [field_name]: [] });
      } else {
        setProductFields({
          ...productFields,
          [field]: productFields[field].filter((n) => {
            return n != item.id;
          }),
          [field_name]: productFields[field_name].filter((n) => {
            return n != item.name;
          }),
        });
      }
    } else {
      if (field === "medicine_format") {
        setProductFields({
          ...productFields,
          [field]: [item.id],
          [field_name]: [item.name],
        });
      } else {
        setProductFields({
          ...productFields,
          [field]: [...productFields[field], item.id],
          [field_name]: [...productFields[field_name], item.name],
        });
      }
    }
  };

  const showCategories = (category, field, field_name) => {
    return (
      <div style={{ marginLeft: "4vh" }}>
        {category?.children?.length === 0 || !category.children ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              addOrRemoveProductFields(category.obj, field, field_name);
            }}
          >
            {productFields[field].find(
              (element) => element === category.obj.id
            ) ? (
              <img
                src={birdie}
                alt="birdie"
                style={{ height: "1.5vh", paddingRight: "1.5vh" }}
              />
            ) : null}
            <h1
              style={
                productFields[field].find(
                  (element) => element === category.obj.id
                )
                  ? { color: "#20b900" }
                  : null
              }
              className={styles.empty_category}
            >
              {category.obj.name}
            </h1>
          </div>
        ) : (
          <div>
            <div
              className={
                openCategory.find((element) => element === category.obj.id)
                  ? styles.open_category
                  : styles.category
              }
              onClick={() => {
                if (
                  openCategory.find((element) => element === category.obj.id)
                ) {
                  setOpenCategory(
                    openCategory.filter((item) => item !== category.obj.id)
                  );
                } else {
                  setOpenCategory([...openCategory, category.obj.id]);
                }
              }}
            >
              <h1>{category.obj.name}</h1>
              {openCategory.find((element) => element === category.obj.id) ? (
                <img
                  src={arrow_green}
                  alt="arrow_green"
                  style={{ height: "0.8vh", padding: "0 2vh" }}
                />
              ) : (
                <img
                  src={arrow_2}
                  alt="arrow_2"
                  style={{ height: "1.5vh", paddingLeft: "1vh" }}
                />
              )}
            </div>
            {openCategory.find((element) => element === category.obj.id) && (
              <div className={styles.part_categories}>
                {category.children.map((item, index) =>
                  item?.children?.length === 0 || !category.children ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "4vh",
                      }}
                      key={index}
                    >
                      {productFields[field].find(
                        (element) => element === item.obj.id
                      ) ? (
                        <img
                          src={birdie}
                          alt="birdie"
                          style={{ height: "1.5vh", paddingRight: "1.5vh" }}
                        />
                      ) : null}
                      <h1
                        className={styles.empty_category}
                        style={
                          productFields[field].find(
                            (element) => element === item.obj.id
                          )
                            ? { color: "#20b900" }
                            : null
                        }
                        onClick={() => {
                          addOrRemoveProductFields(item.obj, field, field_name);
                        }}
                      >
                        {item.obj.name}
                      </h1>
                    </div>
                  ) : (
                    showCategories(item, field, field_name)
                  )
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    getMedicineCreateProduct();
  }, [productFields.type_of_medicine]);
  return (
    <div className={styles.container}>
      <Helmet title="Добавить Товар">
        <link rel="canonical" href="/seller/createproduct" />
        <meta
          name="description"
          content='Форма добавления товара на сайт "Shalfey".'
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1 className={styles.heading}>Создание товара</h1>
      <div className={styles.steps}>
        {tab === 1 ? (
          <>
            <div>
              <img src={step_1} alt="Шаг1" />
              <h3 style={{ marginLeft: "2vh" }}>Информация о товаре</h3>
            </div>
            <div style={{ margin: "0 5vh" }}>
              <img src={step_2_2} alt="Шаг2" />
              <h3 style={{ color: "#CBCBCB", marginLeft: "2vh" }}>
                Способ доставки
              </h3>
            </div>
            <div style={{ margin: "0 5vh" }}>
              <img src={step_3_2} alt="Шаг3" />
              <h3 style={{ color: "#CBCBCB", marginLeft: "2vh" }}>
                Предварительный просмотр
              </h3>
            </div>
          </>
        ) : tab === 2 ? (
          <>
            <div>
              <img src={step_1_2} alt="Шаг1" />
              <h3 style={{ color: "#CBCBCB", marginLeft: "2vh" }}>
                Информация о товаре
              </h3>
            </div>
            <div style={{ margin: "0 5vh" }}>
              <img src={step_2} alt="Шаг2" />
              <h3 style={{ marginLeft: "2vh" }}>Способ доставки</h3>
            </div>
            <div style={{ margin: "0 5vh" }}>
              <img src={step_3_2} alt="Шаг3" />
              <h3 style={{ color: "#CBCBCB", marginLeft: "2vh" }}>
                Предварительный просмотр
              </h3>
            </div>
          </>
        ) : (
          <>
            <div>
              <img src={step_1_2} alt="Шаг1" />
              <h3 style={{ color: "#CBCBCB", marginLeft: "2vh" }}>
                Информация о товаре
              </h3>
            </div>
            <div style={{ margin: "0 5vh" }}>
              <img src={step_2_2} alt="Шаг2" />
              <h3 style={{ color: "#CBCBCB", marginLeft: "2vh" }}>
                Способ доставки
              </h3>
            </div>
            <div style={{ margin: "0 5vh" }}>
              <img src={step_3} alt="Шаг3" />
              <h3 style={{ marginLeft: "2vh" }}>Предварительный просмотр</h3>
            </div>
          </>
        )}
      </div>
      {tab === 3 ? (
        <div className={styles.review}>
          <div
            style={{
              backgroundColor: "#FFFFFF",
              margin: "5vh 0",
              padding: "5vh 4vw 0 1vw",
            }}
          >
            <ProductInfo
              product={{
                ...productFields,
                indications: productFields.indications_names,
                contraindications: productFields.contradictions,
                symptoms: productFields.symptoms_names,
                year_classification: productFields.year_names,
                type_of_medicine: productFields.medicine_format_names,
                box_count: productFields.count,
                price: productFields.price,
                pic_urls: images.map((item) => ({
                  pic_url: item,
                })),
              }}
              create_product={true}
            />
          </div>
        </div>
      ) : tab === 2 ? (
        <div>
          <h3 className={styles.title}>Выберите способ доставки</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className={styles.delivery}
              style={{
                backgroundColor:
                  productFields.delivery_type === "CR" ? "#EFFAF0" : "#F8FBF9",
              }}
              onClick={() =>
                setProductFields({
                  ...productFields,
                  delivery_type: "CR",
                })
              }
            >
              <div style={{ display: "flex" }}>
                <Checkbox
                  isSelected={productFields.delivery_type === "CR"}
                  width="2vh"
                  height="2vh"
                  marginRight="1vw"
                  styleBack={{ padding: "1px", borderRadius: "3px" }}
                  change={() =>
                    setProductFields({
                      ...productFields,
                      delivery_type: "CR",
                    })
                  }
                />
                <p className={styles.checkbox}>Доставка курьером</p>
              </div>
              <p>(курьер забирает посылку со склада)</p>
            </div>
            <div
              className={styles.delivery}
              style={{
                backgroundColor:
                  productFields.delivery_type === "TM" ? "#EFFAF0" : "#F8FBF9",
              }}
              onClick={() =>
                setProductFields({
                  ...productFields,
                  delivery_type: "TM",
                })
              }
            >
              <div style={{ display: "flex" }}>
                <Checkbox
                  isSelected={productFields.delivery_type === "TM"}
                  width="2vh"
                  height="2vh"
                  marginRight="1vw"
                  styleBack={{ padding: "1px", borderRadius: "3px" }}
                  change={() =>
                    setProductFields({
                      ...productFields,
                      delivery_type: "TM",
                    })
                  }
                />
                <p className={styles.checkbox}>Доставка через почтоматы </p>
              </div>
              <p>(доставка отправок осуществляется через почтомат)</p>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <div className={styles.part}>
            <div>
              <div className={styles.title}>
                <h3>Основная информация</h3>
              </div>
              <div className={styles.content}>
                <input
                  value={productFields.name}
                  placeholder="Название"
                  style={{
                    border: emptyFields.includes("name")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      name: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.brand}
                  placeholder="Бренд"
                  style={{
                    border: emptyFields.includes("brand")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      brand: event.target.value,
                    });
                  }}
                />

                <div
                  className={styles.medicine}
                  style={{
                    border: emptyFields.includes("type_of_medicine")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onClick={() =>
                    setOpenSelect({
                      openMed: !openSelect.openMed,
                    })
                  }
                >
                  <p>
                    {productFields.type_of_medicine_names.length == 0
                      ? "Вид медицины"
                      : productFields.type_of_medicine_names}
                  </p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openMed && (
                  <div className={styles.types_of_medicine}>
                    {medicine?.map((item, index) => (
                      <div
                        key={index}
                        className={styles.type}
                        onClick={() => openCategories(item.id, item.name)}
                      >
                        <p
                          className={
                            productFields.type_of_medicine === item.id &&
                            styles.open_medicine
                          }
                        >
                          {item.name}
                        </p>
                      </div>
                    ))}
                  </div>
                )}

                <input
                  value={productFields.composition}
                  placeholder="Состав"
                  style={{
                    border: emptyFields.includes("composition")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) =>
                    setProductFields({
                      ...productFields,
                      composition: event.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div>
              <div className={styles.title}>
                <h3> Дополнительная информация </h3>
              </div>
              <div className={styles.content}>
                <input
                  value={productFields.description}
                  placeholder="Описание"
                  style={{
                    border: emptyFields.includes("description")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      description: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.contradictions}
                  placeholder="Противопоказания"
                  style={{
                    border: emptyFields.includes("contradictions")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      contradictions: event.target.value,
                    });
                  }}
                />
                <div
                  className={styles.medicine}
                  style={{
                    border: emptyFields.includes("medicine_format")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onClick={() => {
                    setOpenSelect({
                      openMedicineForms: !openSelect.openMedicineForms,
                    });
                  }}
                >
                  <p>
                    {productFields.medicine_format_names.length == 0
                      ? "Форма выпуска"
                      : productFields.medicine_format_names}
                  </p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openMedicineForms && (
                  <div className={styles.category_block}>
                    {medicine_forms?.map((item) =>
                      showCategories(
                        item,
                        "medicine_format",
                        "medicine_format_names"
                      )
                    )}
                  </div>
                )}
                <div>
                  <input
                    value={productFields.application_methods}
                    placeholder="Способ применения "
                    style={{
                      width: "22vw",
                      border: emptyFields.includes("application_methods")
                        ? "2px solid #DC7413"
                        : null,
                    }}
                    onChange={(event) => {
                      setProductFields({
                        ...productFields,
                        application_methods: event.target.value,
                      });
                    }}
                  />
                  <input
                    value={productFields.dosage}
                    placeholder="Дозировка"
                    style={{
                      width: "22vw",
                      border: emptyFields.includes("dosage")
                        ? "2px solid #DC7413"
                        : null,
                    }}
                    onChange={(event) => {
                      setProductFields({
                        ...productFields,
                        dosage: event.target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <input
                    value={productFields.storage_conditions}
                    placeholder="Условия хранения и срок годности"
                    style={{
                      width: "22vw",
                      border: emptyFields.includes("storage_conditions")
                        ? "2px solid #DC7413"
                        : null,
                    }}
                    onChange={(event) => {
                      setProductFields({
                        ...productFields,
                        storage_conditions: event.target.value,
                      });
                    }}
                  />

                  <input
                    value={productFields.manufacturer_country}
                    placeholder="Страна производитель"
                    style={{
                      width: "22vw",
                      border: emptyFields.includes("manufacturer_country")
                        ? "2px solid #DC7413"
                        : null,
                    }}
                    onChange={(event) => {
                      setProductFields({
                        ...productFields,
                        manufacturer_country: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={styles.title_pic}>
                <h1>Фото</h1>
                {images.length < 9 && (
                  <>
                    <label
                      htmlFor="picfile"
                      style={{
                        cursor: "pointer",
                        color:
                          pics[0].picProductFields === ""
                            ? "#DC7413"
                            : "#20B900",
                      }}
                    >
                      Добавить фото
                    </label>
                    <input
                      type="file"
                      id="picfile"
                      name="picfile"
                      onChange={(event) => {
                        if (event.target.files[0])
                          setImages([...images, event.target.files[0]]);
                      }}
                    />
                  </>
                )}
              </div>
              <div className={styles.pics}>
                <div className={styles.main_photo}>
                  <img
                    className={styles.img}
                    src={
                      images.length === 0
                        ? pics[0].pic
                        : URL.createObjectURL(images[0])
                    }
                    alt={pics[0].nameProductFields}
                  />
                  {images.length !== 0 && (
                    <img
                      className={styles.delete}
                      src={delete_image}
                      alt="Удалить картинку"
                      onClick={() => deletePicture(images[0])}
                    />
                  )}
                </div>
                <div className={styles.small_photo}>
                  {pics.slice(1).map((item, index) => (
                    <div key={index}>
                      <img
                        className={styles.img}
                        src={
                          images[index + 1]
                            ? URL.createObjectURL(images[index + 1])
                            : item.pic
                        }
                        alt={item.nameProductFields}
                      />
                      {images[index + 1] && (
                        <img
                          className={styles.delete}
                          src={delete_image}
                          alt="Удалить картинку"
                          onClick={() => deletePicture(images[index + 1])}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.part}>
            <div>
              <div className={styles.title}>
                <h3> Категории</h3>
              </div>
              <div className={styles.content}>
                <div
                  className={styles.medicine}
                  onClick={() => {
                    setOpenSelect({
                      openCat: !openSelect.openCat,
                    });
                  }}
                >
                  <p>
                    {productFields.categories_names.length == 0
                      ? "Категории"
                      : productFields.categories_names.join(", ")}
                  </p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openCat && (
                  <div className={styles.category_block}>
                    {productFields.type_of_medicine && categories.length ? (
                      categories?.map((item) =>
                        showCategories(item, "categories", "categories_names")
                      )
                    ) : productFields.type_of_medicine ? (
                      <p>Нет категорий</p>
                    ) : (
                      <p>Выберите вид медицины</p>
                    )}
                  </div>
                )}
                <div
                  className={styles.medicine}
                  style={{
                    border: emptyFields.includes("indications")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onClick={() => {
                    setOpenSelect({
                      openIllnesses: !openSelect.openIllnesses,
                    });
                  }}
                >
                  <p>
                    {productFields.indications_names.length == 0
                      ? "Показания"
                      : productFields.indications_names.join(", ")}
                  </p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openIllnesses && (
                  <div className={styles.category_block}>
                    {indications?.map((item) =>
                      showCategories(item, "indications", "indications_names")
                    )}
                  </div>
                )}
                <div
                  className={styles.medicine}
                  style={{
                    border: emptyFields.includes("symptoms")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onClick={() => {
                    setOpenSelect({
                      openSymptomGroups: !openSelect.openSymptomGroups,
                    });
                  }}
                >
                  <p>
                    {productFields.symptoms_names.length == 0
                      ? "Симптомы"
                      : productFields.symptoms_names.join(", ")}
                  </p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openSymptomGroups && (
                  <div className={styles.category_block}>
                    {symptom_groups?.map((item) =>
                      showCategories(item, "symptoms", "symptoms_names")
                    )}
                  </div>
                )}
                <div
                  className={styles.medicine}
                  style={{
                    border: emptyFields.includes("year")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onClick={() => {
                    setOpenSelect({
                      openYearClassification:
                        !openSelect.openYearClassification,
                    });
                  }}
                >
                  <p>
                    {productFields.year_names.length == 0
                      ? "Возраст"
                      : productFields.year_names.join(", ")}
                  </p>
                  <img src={vector_25} alt="vector_25" />
                </div>
                {openSelect.openYearClassification && (
                  <div className={styles.category_block}>
                    {year_classification?.map((item) =>
                      showCategories(item, "year", "year_names")
                    )}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={styles.title}>
                <h3>Габариты</h3>
              </div>
              <div className={styles.content}>
                <input
                  value={productFields.length}
                  type="number"
                  min="0"
                  placeholder="Длина (см.)"
                  style={{
                    border: emptyFields.includes("length")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) =>
                    setProductFields({
                      ...productFields,
                      length: event.target.value,
                    })
                  }
                />
                <input
                  value={productFields.width}
                  type="number"
                  min="0"
                  placeholder="Ширина (см.)"
                  style={{
                    border: emptyFields.includes("width")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) =>
                    setProductFields({
                      ...productFields,
                      width: event.target.value,
                    })
                  }
                />
                <input
                  value={productFields.height}
                  type="number"
                  min="0"
                  placeholder="Высота (см.)"
                  style={{
                    border: emptyFields.includes("height")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) =>
                    setProductFields({
                      ...productFields,
                      height: event.target.value,
                    })
                  }
                />
                <input
                  value={productFields.weight}
                  type="number"
                  min="0"
                  placeholder="Вес (гр.)"
                  style={{
                    border: emptyFields.includes("weight")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) =>
                    setProductFields({
                      ...productFields,
                      weight: event.target.value,
                    })
                  }
                />
                <input
                  type="number"
                  min="0"
                  value={productFields.count}
                  placeholder="Количество в упаковке"
                  style={{
                    border: emptyFields.includes("count")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      count: event.target.value,
                    });
                  }}
                />
                <div className={styles.tax}>
                  <h1
                    style={{
                      color: emptyFields.includes("vat") && "#DC7413",
                    }}
                  >
                    {" "}
                    НДС (налог на добавленную стоимость)
                  </h1>
                  <div className={styles.types_tax}>
                    <div
                      onClick={() => {
                        setProductFields({
                          ...productFields,
                          vat: "SV",
                        });
                      }}
                    >
                      {productFields.vat === "SV" ? "◉ 10%" : "〇 10%"}
                    </div>
                    <div
                      onClick={() => {
                        setProductFields({
                          ...productFields,
                          vat: "BV",
                        });
                      }}
                    >
                      {productFields.vat === "BV" ? "◉ 20%" : "〇 20%"}
                    </div>
                    <div
                      onClick={() => {
                        setProductFields({
                          ...productFields,
                          vat: "NV",
                        });
                      }}
                    >
                      {productFields.vat === "NV"
                        ? "◉ Не облагается"
                        : "〇 Не облагается"}
                    </div>
                  </div>
                </div>
                <input
                  value={productFields.vendor_code}
                  placeholder="Артикул"
                  style={{
                    border: emptyFields.includes("vendor_code")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      vendor_code: event.target.value,
                    });
                  }}
                />
                <input
                  value={productFields.barcode}
                  placeholder="Штрих-код"
                  style={{
                    border: emptyFields.includes("barcode")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      barcode: event.target.value,
                    });
                  }}
                />
                <input
                  type="number"
                  min="0"
                  value={productFields.price}
                  placeholder="Цена"
                  style={{
                    border: emptyFields.includes("price")
                      ? "2px solid #DC7413"
                      : null,
                  }}
                  onChange={(event) => {
                    setProductFields({
                      ...productFields,
                      price: event.target.value,
                    });
                  }}
                />
                {productFields.price && (
                  <h1>{`Прибыль с товара с вычетом комиссии маркетплейса: ${(
                    productFields.price * 0.8
                  ).toFixed(2)}₽`}</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {errorMessage && <p>{errorMessage}</p>}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        {tab === 1 ? (
          <>
            <button
              className={styles.cancel}
              onClick={() => navigate("/seller/shop/products")}
            >
              Отмена
            </button>
            <button
              className={styles.further}
              onClick={() =>
                checkProperties(productFields)
                  ? showFields(checkProperties(productFields))
                  : setTab(2)
              }
            >
              Далее
            </button>
          </>
        ) : tab === 2 ? (
          <>
            <button className={styles.cancel} onClick={() => setTab(1)}>
              Назад
            </button>
            <button className={styles.further} onClick={() => setTab(3)}>
              Далее
            </button>
          </>
        ) : (
          <>
            <button className={styles.cancel} onClick={() => setTab(2)}>
              Назад
            </button>
            <button className={styles.further} onClick={createProduct}>
              Сохранить
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export { CreateProduct };
