import React, { useState, useEffect } from "react";
import styles from "./Shop.module.scss";
import { vh, vw } from "plugins/convertPXtoVHVW";
import { useParams, useSearchParams } from "react-router-dom";
import { axiosMyShop } from "plugins/axios";
import zoom from "assets/img/zoom.svg";
import { ModalImg, ModalTwoButtons } from "components";
import star from "assets/img/star_12.svg";
import new_star from "assets/img/new_star.svg";
import star_13 from "assets/img/star_13.svg";
import { getFormattedDate } from "plugins/getFormattedDate";
import { axiosSeller } from "plugins/axios";

const ReviewMyProduct = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const [product, setProduct] = useState({});
  const [reviews, setReviews] = useState([]);

  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedImageReview, setSelectedImageReview] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenReview, setModalOpenReview] = useState(-1);

  const [wasLastReviews, setWasLastReviews] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(2);

  const [idForAnswer, setIdForAnswer] = useState(-1);
  const [textAnswer, setTextAnswer] = useState("");

  useEffect(() => {
    const getProduct = async () => {
      let resProduct = await axiosMyShop.getProductForReviews(
        id,
        searchParams.get("type") || "archive"
      );
      if (!resProduct?.response?.status) {
        setProduct(resProduct);
      }
    };

    getProduct();
  }, []);
  useEffect(() => {
    const getReviews = async () => {
      await axiosSeller.getSellerNotificationsCount();
      let resReviews = await axiosMyShop.getReviewsForProduct(
        id,
        searchParams.get("type") || "archive"
      );
      if (resReviews?.results) {
        setReviews(
          !resReviews.next
            ? resReviews.results || []
            : [...reviews, ...resReviews.results]
        );

        setNextPage(!resReviews.next ? currentPage : currentPage + 1);
        setWasLastReviews(!resReviews.next);
      }
    };

    if (!wasLastReviews) {
      getReviews();
    }
  }, [wasLastReviews, currentPage]);

  const answerReview = async (idReview) => {
    if (textAnswer.trim().length) {
      let resAnswerReview = await axiosMyShop.answerReview(
        idReview,
        textAnswer
      );
      if (!resAnswerReview) {
        let review = reviews.find((review) => review.id === idReview);
        if (review?.is_pinned) {
          await axiosMyShop.pinReview(idReview);
        }
        setReviews(reviews.filter((review) => review.id !== idReview));
      }
    }
  };
  const pinReview = async (idReview) => {
    let resPin = await axiosMyShop.pinReview(idReview);
    if (!resPin) {
      setReviews(
        reviews.map((review) =>
          review.id === idReview
            ? { ...review, is_pinned: !review.is_pinned }
            : review
        )
      );
    }
  };

  return (
    <div className={styles.review__cont}>
      <div className={styles.review__product}>
        <div
          className={styles.review__product__image}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div className={styles.all_pic}>
            {product.pic_urls?.length !== 0
              ? product.pic_urls?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      border: `1px solid #20b900${
                        selectedImage === index ? "ff" : "00"
                      }`,
                    }}
                    onClick={() => {
                      setSelectedImage(index);
                    }}
                  >
                    <img
                      src={item.pic_url}
                      alt={item.alt || `Товар ${index}`}
                    />
                  </div>
                ))
              : null}
          </div>
          <div className={styles.main_pic}>
            {product.pic_urls
              ? product.pic_urls?.length !== 0 && (
                  <img
                    src={product?.pic_urls[selectedImage]?.pic_url}
                    className={styles.main_img}
                    alt={
                      product?.pic_urls[selectedImage]?.alt ||
                      `Товар ${selectedImage}`
                    }
                  />
                )
              : null}
            <img
              src={zoom}
              className={styles.zoom}
              alt="Увеличить"
              onClick={() => {
                setModalOpen(!modalOpen);
              }}
            />
          </div>
        </div>
        {modalOpen ? (
          <ModalImg
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            pic_urls={product.pic_urls}
            main_img={selectedImage}
          />
        ) : null}
        <div className={styles.review__product__info}>
          <div className={styles.review__product__vendor}>
            Артикул: {product.vendor_code}
          </div>
          <div className={styles.review__product__name}>{product.name}</div>
          <div className={styles.review__product__rating}>
            <span>Рейтинг товара: </span>
            <img src={star} width={14} height={14} alt="Оценка " />
            {product.product_mark}
          </div>
          <div className={styles.review__product__medicine}>
            <span>Мир медицины:</span>
            {product.type_of_medicine}
          </div>
          <div className={styles.review__product__brand}>
            <span>Бренд:</span>
            {product.brand}
          </div>
          <div className={styles.review__product__count_reviews}>
            <span>Количество отзывов:</span>
            {product.review_count}
          </div>
        </div>
      </div>
      <div className={styles.review__reviews}>
        {reviews.length ? (
          reviews.map((review, index) => (
            <div key={index} className={styles.review_card}>
              <div className={styles.review_card__header}>
                <div className={styles.review_card__header__left}>
                  <div className={styles.review_card__header__rating}>
                    {[...Array(review.mark)].map((x, i) => (
                      <img key={i} src={new_star} alt="Звезда" />
                    ))}
                    {[...Array(5 - review.mark)].map((x, i) => (
                      <img key={i} src={star_13} alt="Звезда" />
                    ))}
                  </div>
                  <div className={styles.review_card__header__name}>
                    {review.username},
                  </div>
                  <div className={styles.review_card__header__date}>
                    {getFormattedDate(review?.creation_date || "")}
                  </div>
                </div>
                {searchParams.get("type") !== "archive" ? (
                  <div className={styles.review_card__header__right}>
                    <div
                      className={styles.review_card__header__pin}
                      onClick={() => {
                        pinReview(review.id);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={vh(16)}
                        height={vh(16)}
                        viewBox="0 0 16 16"
                        fill="none"
                        style={{ marginRight: vw(2) }}
                      >
                        <path
                          d="M6.33333 3.82917H6.24583V3.91667V11.2083C6.24583 12.0617 6.93834 12.7542 7.79167 12.7542C8.64499 12.7542 9.3375 12.0617 9.3375 11.2083V3.625C9.3375 2.13001 8.11999 0.9125 6.625 0.9125C5.13001 0.9125 3.9125 2.13001 3.9125 3.625V11.2083C3.9125 13.345 5.65501 15.0875 7.79167 15.0875C9.92833 15.0875 11.6708 13.345 11.6708 11.2083V2.75V2.6625H11.5833H11H10.9125V2.75V11.2083C10.9125 12.9275 9.51084 14.3292 7.79167 14.3292C6.07249 14.3292 4.67083 12.9275 4.67083 11.2083V3.625C4.67083 2.54749 5.54749 1.67083 6.625 1.67083C7.70251 1.67083 8.57917 2.54749 8.57917 3.625V11.2083C8.57917 11.6442 8.22751 11.9958 7.79167 11.9958C7.35583 11.9958 7.00417 11.6442 7.00417 11.2083V3.91667V3.82917H6.91667H6.33333Z"
                          fill="#5F6B69"
                          stroke="#5F6B69"
                          strokeWidth="0.175"
                        />
                      </svg>
                      {review.is_pinned ? "Открепить" : "Закрепить"}
                    </div>
                    <div
                      className={styles.review_card__header__answer}
                      onClick={() => {
                        setTextAnswer("");
                        setIdForAnswer(review.id);
                      }}
                    >
                      Ответить
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {review?.pic_urls && review?.pic_urls.length ? (
                <div className={styles.review_card__images}>
                  {review.pic_urls.map((pic, index) => (
                    <img
                      key={index}
                      src={pic.pic_url}
                      alt="Фото отзыва"
                      onClick={() => {
                        setSelectedImageReview(index);
                        setModalOpenReview(review.id);
                      }}
                    />
                  ))}
                </div>
              ) : (
                <></>
              )}
              <div className={styles.review_card__comment}>
                {review.comment}
              </div>
              {review?.answer && (
                <div className={styles.review_card__answer}>
                  <div>Ваш ответ</div>
                  <div>{review.answer}</div>
                </div>
              )}
              {modalOpenReview === review.id ? (
                <ModalImg
                  isOpen={modalOpenReview}
                  onClose={() => setModalOpenReview(-1)}
                  pic_urls={review.pic_urls}
                  main_img={selectedImageReview}
                />
              ) : null}
            </div>
          ))
        ) : (
          <div className={styles.review__reviews__empty}>Нет отзывов</div>
        )}
      </div>
      {idForAnswer > -1 && (
        <ModalTwoButtons
          title="Ваш ответ"
          description={
            <textarea
              className={styles.reviews__textarea}
              placeholder="Введите сообщение"
              value={textAnswer}
              onChange={(e) => setTextAnswer(e.target.value)}
            />
          }
          button={{
            title: "Ответить",
            action: () => {
              setIdForAnswer(-1);
              answerReview(idForAnswer);
            },
          }}
          width={vw(874)}
          buttonStyle={{
            width: "100%",
            padding: `${vh(16)} ${vw(32)}`,
            fontSize: vh(18),
            lineHeight: vh(24),
          }}
          buttonClose={{ title: "Закрыть" }}
          close={() => {
            setIdForAnswer(-1);
            setTextAnswer("");
          }}
        />
      )}
    </div>
  );
};

export { ReviewMyProduct };
