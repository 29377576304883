import React, { useState, useEffect, useRef } from "react";
import styles from "./OrdersHandler.module.scss";
import {
  ModalNoButton,
  ModalOneButton,
  Button,
  Input,
  Tabs,
  OrderDetails,
  ModalTwoButtons,
  Documents,
  Select,
  ModalСontacts,
} from "components";

import search_icon from "assets/img/search.svg";
import edit from "assets/img/review_edit.svg";
import details from "assets/img/details.svg";
import documentation from "assets/img/documentation.svg";
import cancel from "assets/img/cancel.svg";

import { Helmet } from "react-helmet";
import { axiosDeliveries, axiosSeller } from "plugins/axios";
import { DELIVERIES_STATUSES } from "constants/purchaseStatuses";
import { PROFILE } from "constants/url";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { checkError } from "plugins/checkError";

const OrdersHandler = () => {
  const navigate = useNavigate();
  const debounceTimeout = useRef(null);
  const [orders, setOrders] = useState([]);
  const [sorting, setSorting] = useState("all");
  const [searchText, setSearchText] = useState("");

  const [editItem, setEditItem] = useState(0);
  const [modalDetails, setModalDetails] = useState(false);

  const [isLoadingModalOrder, setIsLoadingModalOrder] = useState(false);
  const [modalOrder, setModalOrder] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [terminal, setTerminal] = useState(0);
  const [error, setError] = useState(0);
  const [modalOrderItem, setModalOrderItem] = useState({
    order_number: 0,
    pickup_address: "",
    delivery_service: "",
  });

  const [modalCancelOrder, setModalCancelOrder] = useState(false);
  const [modalChangeStatus, setModalChangeStatus] = useState(false);
  const [editStatusItem, setEditStatusItem] = useState("");
  const [newStatus, setNewStatus] = useState("");

  const [errorMessage, setErrorMessage] = useState(true);
  const [modalCancelOrderService, setModalCancelOrderService] = useState(false);
  const [isOpenModalСontacts, setIsOpenModalСontacts] = useState(false);
  const [cancelOrderAllow, setCancelOrderAllow] = useState(true);

  const [modalDocuments, setModalDocuments] = useState(false);
  const [orderItemDocuments, setOrderItemDocuments] = useState(0);
  const [documentUpload, setDocumentUpload] = useState([]);

  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);

  const [cityText, setCityText] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorCity, setErrorCity] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [isCitySelected, setCitySelected] = useState(false);
  const [openCity, setOpenCity] = useState(false);

  const getOrders = async () => {
    await axiosSeller.getSellerNotificationsCount();
    let result = await axiosDeliveries.getOrders(
      "type=" + sorting + "&page=" + currentPage + "&query=" + searchText
    );
    if (result?.response?.status === 403) {
      navigate(PROFILE.url);
    }
    if (!result?.results?.length || result?.message) {
      setWasLastList(true);
      return;
    }
    setPreviousPage(currentPage);
    setOrders([...orders, ...result.results]);
  };

  const editStatus = async () => {
    await axiosDeliveries.changeOrderStatus(editStatusItem, newStatus);
    setOrders([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
    setModalChangeStatus(false);
  };

  const downloadDocs = () => {
    documentUpload.forEach(async (element) => {
      let resp = await axiosDeliveries.downloadDoc(element.file_url);
      const blob = new Blob([resp], {
        type: "application/pdf",
      });
      let a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.setAttribute("download", element.name);
      a.click();
    });
  };

  const createCancelOrder = async () => {
    let result = await axiosDeliveries.cancelOrder(editStatusItem);
    let { isError, messageError } = checkError(result);
    if (isError) {
      setErrorMessage(messageError);
    } else {
      if (result.data.status === "sent") {
        setCancelOrderAllow(false);
      } else if (result.data.status === "contact") {
        setModalCancelOrderService(true);
        setModalCancelOrder(false);
      } else {
        setOrders([]);
        setWasLastList(false);
        setCurrentPage(1);
        setPreviousPage(0);
        setModalCancelOrder(false);
      }
    }
  };

  const getCities = async () => {
    let resCities = await axiosSeller.getTerminals(cityText);
    setAddresses(resCities);
    setIsLoading(false);
  };

  const createOrder = async () => {
    setIsLoadingModalOrder(true);
    if (
      !(
        (modalOrderItem.pickup_address === "" &&
          terminal === 0 &&
          modalOrderItem.delivery_service === "DPD") ||
        !time ||
        !date
      )
    ) {
      let res = await axiosDeliveries.createOrder(modalOrderItem.order_number, {
        time,
        date: moment(new Date(date)).format("DD.MM.YYYY"),
        terminal,
      });
      let { isError, messageError } = checkError(res);
      if (isError) {
        setError(messageError);
      } else {
        setOrders([]);
        setWasLastList(false);
        setCurrentPage(1);
        setPreviousPage(0);
        setModalOrder(false);
        setModalOrderItem({
          order_number: 0,
          pickup_address: "",
          delivery_service: "",
        });
        setTime("");
        setDate("");
        setTerminal(0);
        setCityText("");
      }
    } else {
      setError("Заполните все поля");
    }
    setIsLoadingModalOrder(false);
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getOrders();
    }
  }, [currentPage, previousPage, wasLastList]);

  useEffect(() => {
    setOrders([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  }, [sorting]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    if (isSearch) {
      getCities();
      setIsSearch(false);
    } else {
      debounceTimeout.current = setTimeout(() => {
        getCities();
      }, 1500);
    }

    return () => clearTimeout(debounceTimeout.current);
  }, [cityText, isSearch]);

  return (
    <div className={styles.content} onScroll={onScroll}>
      <Helmet title="Управление заказами">
        <meta name="robots" content="noindex" />
        <meta name="description" content="Shalfey : Управление заказами" />
        <link rel="canonical" href="/seller/deliveries/orders_handler" />
      </Helmet>
      <h1 className={styles.heading}>Управление заказами</h1>
      <Tabs
        tabs={[
          { name: "all", title: "Все заказы" },
          { name: "new", title: "Новые заказы" },
          { name: "processing", title: "В обработке" },
          { name: "sent", title: "Переданные в доставку" },
          { name: "delivered", title: "Доставлены" },
          { name: "canceled", title: "Отменены" },
        ]}
        currentTab={sorting}
        margin="4vh 0"
        changeTab={setSorting}
      />
      <Input
        light
        placeholder="Введите артикул или название товара"
        value={searchText}
        fontColor="#5F6B69"
        width="18vw"
        margin="4vh 0"
        prepend={
          <img
            src={search_icon}
            style={{ height: "2.5vh" }}
            alt="search"
            onClick={() => {
              setOrders([]);
              setWasLastList(false);
              setCurrentPage(1);
              setPreviousPage(0);
            }}
          />
        }
        onChange={(e) => setSearchText(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setOrders([]);
            setWasLastList(false);
            setCurrentPage(1);
            setPreviousPage(0);
          }
        }}
      />
      <table>
        <thead>
          <tr>
            <td>Номер заказа</td>
            <td>Дата, время</td>
            {sorting !== "canceled" && <td>Статус</td>}
            <td>Покупатель</td>
            <td>Cостав заказа</td>
            <td>Статус {sorting === "canceled" ? "заказа" : "оплаты"}</td>
            <td>
              {sorting === "canceled"
                ? "Дополнительная информация"
                : "Рекомендации"}
            </td>
            {sorting !== "canceled" && <td></td>}
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.order_number}>
              <td>{order.order_number}</td>
              <td>
                <p>{order.transaction_date.slice(0, 10)}</p>
                <p>{order.transaction_date.slice(11, 16)}</p>
              </td>
              {sorting !== "canceled" && (
                <td>
                  <p
                    style={{
                      cursor: "pointer",
                      color:
                        order.status === "PD"
                          ? "#20B900"
                          : order.status === "CL"
                          ? "#DC7413"
                          : "#5F6B69",
                    }}
                    onClick={() => {
                      if (order.status !== "PD") {
                        setEditStatusItem(order.order_number);
                        setModalChangeStatus(true);
                      }
                    }}
                  >
                    {DELIVERIES_STATUSES.find((el) => el.code === order.status)
                      ?.text || ""}
                    <img
                      src={edit}
                      alt="Редактировать"
                      style={{ marginLeft: "0.5vw" }}
                    />
                  </p>
                </td>
              )}
              <td>
                <p>{order.buyer?.fio}</p>
                <p>{order.buyer?.login}</p>
              </td>
              <td>
                {order.content?.map((item, index) => (
                  <div key={index} className={styles.product}>
                    <p>
                      {index + 1}. Арт. {item.product.vendor_code}
                    </p>
                    <p className={styles.one_line}>{item.product.name}</p>
                    <div className={styles.price}>
                      <p>{item.count} шт.</p>
                      <p>{item.price} р.</p>
                    </div>
                  </div>
                ))}
              </td>
              <td style={{ textTransform: "capitalize" }}>
                {sorting === "canceled"
                  ? DELIVERIES_STATUSES.find((el) => el.code === order.status)
                      ?.text || ""
                  : order.payment_status}
              </td>
              <td>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setEditItem(order.order_number);
                    setModalDetails(true);
                  }}
                >
                  <img
                    src={details}
                    alt="Детали заказа"
                    style={{ marginRight: "0.5vw" }}
                  />
                  Детали заказа
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setOrderItemDocuments(order.order_number);
                    setModalDocuments(true);
                  }}
                >
                  <img
                    src={documentation}
                    alt="Документация"
                    style={{ marginRight: "0.5vw" }}
                  />
                  Документация
                </div>
              </td>
              {sorting !== "canceled" && (
                <td>
                  {order.status === "PD" && (
                    <div className={styles.product}>
                      <Button
                        text="Оформить заказ"
                        padding="1vh 0.9vw"
                        marginBottom="1vh"
                        click={() => {
                          setModalOrderItem({
                            order_number: order.order_number,
                            pickup_address: order.pickup_address,
                            delivery_service: order.delivery_service,
                          });
                          setModalOrder(true);
                        }}
                      />
                      <Button
                        text="Отменить заказ"
                        padding="1vh 0.9vw"
                        bgColor="transparent"
                        color="#DC7413"
                        prepend={
                          <img
                            src={cancel}
                            alt="Отменить заказ"
                            style={{
                              width: "1vw",
                              height: "1.3vh",
                              margin: "0",
                            }}
                          />
                        }
                        click={() => {
                          setEditStatusItem(order.order_number);
                          setModalCancelOrder(true);
                        }}
                      />
                    </div>
                  )}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {modalChangeStatus && (
        <ModalOneButton
          title="Изменение статуса заказа"
          description={
            <>
              <div
                className={`${styles.status} ${
                  newStatus === "processing" ? styles.selected : ""
                }`}
                onClick={() => setNewStatus("processing")}
              >
                В обработке
              </div>
              <div className={styles["horizontal-line"]} />
              <div
                className={`${styles.status} ${
                  newStatus === "sent" ? styles.selected : ""
                }`}
                onClick={() => setNewStatus("sent")}
              >
                Передан в доставку
              </div>
              <div className={styles["horizontal-line"]} />
              <div
                className={`${styles.status} ${
                  newStatus === "delivered" ? styles.selected : ""
                }`}
                onClick={() => setNewStatus("delivered")}
              >
                Доставлен
              </div>
              <div className={styles["horizontal-line"]} />
              <div
                className={`${styles.status} ${
                  newStatus === "canceled" ? styles.selected : ""
                }`}
                onClick={() => setNewStatus("canceled")}
              >
                Отменен
              </div>
            </>
          }
          width="30vw"
          button={{
            title: "Сохранить",
            action: editStatus,
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          descriptionAlign="left"
          close={() => setModalChangeStatus(false)}
        />
      )}
      {modalDetails && (
        <ModalNoButton
          title=""
          description={<OrderDetails order_number={editItem} />}
          width="45vw"
          height="90vh"
          overflowY="scroll"
          descriptionAlign="left"
          close={() => setModalDetails(false)}
        />
      )}
      {modalDocuments && (
        <ModalTwoButtons
          title="Документация"
          description={
            <Documents
              order_number={orderItemDocuments}
              setSelectedDoc={setDocumentUpload}
            />
          }
          width="45vw"
          buttonStyle={{
            width: "100%",
            padding: `1.8vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          button={{
            title: "Скачать",
            action: downloadDocs,
          }}
          descriptionAlign="left"
          close={() => setModalDocuments(false)}
        />
      )}
      {modalCancelOrder && (
        <ModalOneButton
          title={
            cancelOrderAllow ? "Отменить заказ" : "Отмена заказа невозможна"
          }
          description={
            cancelOrderAllow ? (
              <>
                <div className={styles.cancel_text}>
                  Вы действительно хотите отменить заказ? Отменить данное
                  действие будет невозможно.
                </div>
                {!!errorMessage && (
                  <span style={{ color: "red" }}>{errorMessage}</span>
                )}
              </>
            ) : (
              <div className={styles.cancel_text}>
                Ваш заказ уже в пути. Отменить заказ невозможно. Вы можете
                оформить возврат товаров после получения заказа.
              </div>
            )
          }
          button={{
            title: cancelOrderAllow ? "Отменить" : "Закрыть",
            action: () => {
              if (cancelOrderAllow) {
                createCancelOrder();
              } else {
                setErrorMessage("");
                setModalCancelOrder(false);
              }
            },
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          width="23vw"
          close={() => setModalCancelOrder(false)}
        />
      )}
      {modalCancelOrderService && (
        <ModalOneButton
          title="Отмена заказа"
          description={
            <div className={styles.cancel_text}>
              Вы можете отменить заказ, написав нам в Службу заботы либо
              связавшись с продавцом
            </div>
          }
          button={{
            title: "Служба заботы",
            action: () => {
              setIsOpenModalСontacts(true);
              setModalCancelOrderService(false);
            },
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          width="23vw"
          close={() => setModalCancelOrderService(false)}
        />
      )}
      {isOpenModalСontacts && (
        <ModalСontacts onClose={() => setIsOpenModalСontacts(false)} />
      )}
      {modalOrder && (
        <ModalTwoButtons
          title="Оформление заказа"
          description={
            <>
              {modalOrderItem.pickup_address === "" &&
                modalOrderItem.delivery_service === "DPD" && (
                  <div className={styles.search}>
                    <Input
                      white
                      errorMessage={errorCity}
                      placeholder="Выберите почтомат"
                      value={cityText}
                      fontColor="#5F6B69"
                      height="5vh"
                      width="100%"
                      borderBottom="1px solid #5F6B6980"
                      inputStyles={{
                        padding: `1.5vh 0.5vw`,
                        boxSizing: "border-box",
                        fontSize: "1.8vh",
                        lineHeight: "2vh",
                      }}
                      prependStyles={{
                        height: "100%",
                        boxSizing: "border-box",
                      }}
                      prepend={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5vh"
                          height="1.6vh"
                          viewBox="0 0 12 13"
                          fill="none"
                          className={openCity ? styles.open : ""}
                          onClick={() => setOpenCity(!openCity)}
                        >
                          <path
                            d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                            stroke="#5F6B69"
                            strokeWidth="0.61828"
                            strokeLinecap="round"
                          />
                        </svg>
                      }
                      onChange={(ev) => {
                        setCitySelected(false);
                        setCityText(ev.target.value);
                        setErrorCity("");
                        setIsLoading(true);
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") setIsSearch(true);
                      }}
                    />
                    {isLoading ? (
                      <div className={styles.search__empty}>Поиск...</div>
                    ) : isCitySelected || !openCity ? (
                      <></>
                    ) : addresses.length ? (
                      <ul className={styles.search__list}>
                        {addresses.map((address) => (
                          <li
                            key={address.id}
                            className={
                              address.address === cityText
                                ? styles.select
                                : null
                            }
                            onClick={() => {
                              setCitySelected(true);
                              setCityText(address.address);
                              setTerminal(address.id);
                              setErrorCity("");
                            }}
                          >
                            <span>{address.address}</span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className={styles.search__empty}>
                        Такого адреса не найдено
                      </div>
                    )}
                  </div>
                )}
              <Input
                light
                label="Дата отправки заказа"
                type="date"
                fontSizeLabel="1.6vh"
                lineHeightLabel="1.8vh"
                value={date}
                marginBottom="3vh"
                onChange={(ev) => setDate(ev.target.value)}
              />
              <label className={styles.label}>Время отправки заказа</label>
              <Select
                value={time}
                width="100%"
                padding="0.5vh"
                marginTop="1.3vh"
                options={[
                  { text: "9-18", value: "9-18" },
                  { text: "9-13", value: "9-13" },
                  { text: "13-18", value: "13-18" },
                ]}
                border="1px solid #E0E3DF"
                onChange={(el) => setTime(el)}
              />
              {!!error && <div style={{ color: "red" }}>{error}</div>}
              {modalOrderItem.pickup_address !== "" && (
                <>
                  <div style={{ marginTop: "2vh" }}>
                    Курьер заберет посылку по указаному адресу:
                  </div>
                  <b>{modalOrderItem.pickup_address}</b>
                </>
              )}
            </>
          }
          descriptionAlign="start"
          button={{
            title: isLoadingModalOrder ? "Оформление..." : "Оформить заказ",
            action: createOrder,
          }}
          buttonStyle={{
            width: "100%",
            padding: `1.4vh 0`,
            fontSize: "1.8vh",
            lineHeight: "2vh",
          }}
          width="25vw"
          close={() => setModalOrder(false)}
        />
      )}
    </div>
  );
};

export { OrdersHandler };
